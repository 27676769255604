export const DAYS_PER_YEAR = 365;
export const HOURS_PER_DAY = 24;
export const MILLISECONDS_PER_SECOND = 1000;
export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_MINUTE = 60;

export const MILLISECONDS_PER_MINUTE: number =
  MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE;

export const MILLISECONDS_PER_HOUR: number =
  MILLISECONDS_PER_MINUTE * MINUTES_PER_HOUR;

export const MILLISECONDS_PER_DAY: number =
  MILLISECONDS_PER_HOUR * HOURS_PER_DAY;

export const MILLISECONDS_PER_YEAR: number =
  MILLISECONDS_PER_DAY * DAYS_PER_YEAR;
